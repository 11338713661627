import Vue from 'vue'
import VueRouter from "vue-router";
import store from './store/store';

Vue.use(VueRouter)


const router = new VueRouter({

  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: { name: 'events.index' },
      component: () => import('@/js/views/Home.vue'),
      name: 'home',
      meta: {
        display_name: 'Home',
        slug: 'home',
        rule: 'operations',
        authRequired: true,
        visible: false
      }
    },
    {
      path: '/login',
      component: () => import('@/js/views/login/Login.vue'),
      name: 'login',
      meta: {
        display_name: 'Login',
        slug: 'login',
        rule: 'user',
        visible: false
      }
    },
    {
        path: '/saml/callback/:response?',
        component: () => import('@/js/views/login/SamlCallback.vue'),
        name: 'saml.callback',
        meta: {
          display_name: 'SAML Callback',
          slug: 'saml.callback',
          rule: 'user',
          visible: false
        }
    },
    // Sensors
    {
      path: '/events',
      component: { render: h => h('router-view') },
      meta: {
        rule: 'operations',
        acl:'events.show',
        slug: 'events.index',
        display_name: 'Sensors',
        authRequired: true,
        visible: true,
        open:true,
        icon: 'tools',
      },
      children:[
        {
          path: '/events/list',
          component: () => import('@/js/views/analytics/events/Events.vue'),
          name: 'events.index',
          meta: {
            rule: 'operations',
            acl: 'events.show',
            slug: 'events.index',
            display_name: 'My Sensors',
            authRequired: true,
            visible: true,
            breadcrumbs: [
              {display_name: 'Home', name: 'home'},
            ],
          }
        },
        {
          path: '/events/account/:account_id?',
          component: () => import('@/js/views/analytics/events/Events.vue'),
          name: 'events.account',
          meta: {
            rule: 'user',
            acl: 'events.show',
            slug: 'events.index',
            display_name: 'My Sensors',
            authRequired: true,
            visible: true,
            breadcrumbs: [
              {display_name: 'Home', name: 'home'},
            ],
          }
        },
        {
          path: '/events/troubleshooting',
          component: () => import('@/js/views/analytics/sensors/SensorTroubleShooting.vue'),
          name: 'sensor.troubleshooting',
          meta: {
            display_name: 'Sensor Troubleshooting',
            slug: 'sensor.troubleshooting',
            rule: 'operations',
            acl: 'allow_all',
            authRequired: true,
            visible: true
          }
        },
      ]
    },
    {
      path: '/surveys',
      component: { render: h => h('router-view') },
      meta: {
        rule: 'client_administrator',
        acl:'surveys.index',
        slug: 'surveys.index',
        display_name: 'Surveys',
        authRequired: true,
        visible: false,
        open:true,
        icon: 'clipboard-data',
      },
      children:[
        // Surveys
        {
          path: '/surveys/list',
          component: () => import('@/js/views/surveys/Surveys.vue'),
          name: 'surveys.index',
          meta: {
            rule: 'client_administrator',
            acl: 'surveys.index',
            slug: 'surveys.index',
            display_name: 'My Surveys',
            authRequired: true,
            visible: true,
            breadcrumbs: [
              {display_name: 'Home', name: 'home'},
            ],
          }
        },
        {
          path: '/surveys/troubleshooting',
          component: () => import('@/js/views/surveys/SurveyTroubleShooting.vue'),
          name: 'survey.troubleshooting',
          meta: {
            display_name: 'Survey Troubleshooting',
            slug: 'survey.troubleshooting',
            rule: 'operations',
            acl: 'allow_all',
            authRequired: true,
            visible: true
          }
        },
      ]
    },
    {
      path: '/connectionfailed',
      component: () => import('@/js/views/ConnectionFailed.vue'),
      name: 'ConnectionFailed',
      meta: {
        display_name: 'Connection Failed',
        slug: 'connectionfailed',
        rule: 'client_administrator',
        authRequired: true,
        visible: false
      }
    },
    {
      path: '/page-not-found',
      component: () => import('@/js/views/PageNotFound.vue'),
      name: 'PageNotFound',
      meta: {
        display_name: 'Page Not Found',
        slug: 'page.not.found',
        rule: 'user',
        authRequired: true,
        visible: false
      }
    },
    /*
    {
      path: '/',
      component: () => import('@/js/views/About.vue'),
      name: 'about',
      meta: {
        display_name: 'About',
        slug: 'about',
        rule: 'client_administrator',
        acl: 'allow_all',
        authRequired: true,
        visible: true
      }
    },

     */
  ]

});


router.beforeEach((to, from, next) => {

  // If auth required, check login. If login fails redirect to login page
  if (to.meta.authRequired) {
    if (!store.getters['auth/isAuthenticated']) {
      store.dispatch('auth/bounceUser', {path: to.path})
    }
  }

  if(to.meta.acl){
    if (!store.getters['auth/hasScope'](to.meta.acl)) {
      store.dispatch('auth/bounceUser', {path: to.path})
    }
  }

  return next()

})


export default router

